export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Clients',
    route: 'client-select',
    icon: 'PieChartIcon',
  },
  {
    header: 'Business',
  },
  {
    title: 'Registered Users',
    route: 'registered-users',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'All Clients',
    route: 'view-clients',
    icon: 'ListIcon',
  },
  {
    title: 'Manage Users',
    route: 'user-manage',
    icon: 'UsersIcon',
  },
  {
    title: 'Manage Modules',
    route: 'module-manage',
    icon: 'BookOpenIcon',
  },
  {
    header: 'Audit',
  },
  {
    title: 'Log Details',
    route: 'view-log',
    icon: 'HardDriveIcon',
  },
  {
    header: 'Subscription',
  },
  {
    title: 'All Subscriptions',
    route: 'package-suscription',
    icon: 'AwardIcon',
  },
  {
    title: 'Billing',
    route: 'billing',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Addons',
    route: 'addons',
    icon: 'AwardIcon',
  },
  {
    title: 'Packages',
    route: 'all-packages',
    icon: 'GiftIcon',
  },
  {
    header: 'Broadcasting ',
  },
  {
    title: 'Client Alerts',
    route: 'client-alerts',
    icon: 'SendIcon',
  },
  {
    header: 'Settings ',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
